import React from 'react';

import InputMask from 'react-input-mask'
import {Range} from 'react-range'

import './index.scss';

const InputField = ({title, style, type, message, placeholder, valid, checked, value, change = () => {}, full = false, checkedFunc = () => {}}) => {
    
    return (
        <div className="form-group">
            <span>{title}</span>
            {type === 'phone' ? (
                <InputMask
                    type="tel"
                    mask="+38 (099) 999-99-99"
                    placeholder={placeholder}
                    value={value}
                    onChange={({target: {value}}) => change(value)}
                    onBlur={() => checkedFunc()}
                />
            ) : (
                <input 
                type={type} 
                style={style}
                placeholder={placeholder}
                value={value}
                 onChange={({target: {value}}) => change(value)} 
                 onBlur={() => checkedFunc()} 
                 />
            )}
            
            {checked ? valid ? null : <p className='input-warning'>{message}</p> : null}
        </div>
    )
}

export default InputField;