import React from "react"
import "./index.scss"

const Checkbox = ({ onClick, checked, message }) => {
  return (
    <div className="form-check-box">
      <div className="cbx-ct">
        <input
          className="inp-cbx"
          id="cbx"
          type="checkbox"
          checked={checked}
          style={{ display: "none" }}
        />
        <label className="cbx" htmlFor="cbx" onClick={onClick}>
          <span>
            <svg width="12px" height="10px" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
          </span>
          <span className="text">
            Я даю згоду на{" "}
            <a
              target={"_blank"}
              href={
                "https://tascombank.ua/files/Povidomlennia_pro_obrobku_PD_221122.pdf"
              }
            >
              обробку та використання моїх персональних даних
            </a>
          </span>
        </label>
      </div>
      {checked ? (
        <></>
      ) : (
        <p className="input-warning input-warning--cashnow">{message}</p>
      )}
    </div>
  )
}

export default Checkbox
