export const validatePhoneNumber = phone => /^380(\d{9})$/.test(phone)

export const validateName = name =>
  /^[А-Яа-яЇїІіЄє\s]+$/.test(name) && name.replace(/\s/g, "").length > 1

export const transformPhoneAfterMask = str => str.replace(/[-+()\s]/g, "")

const validateCheckDigit = inn => {
  // based on https://uk.wikipedia.org/wiki/%D0%9A%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C%D0%BD%D0%B5_%D1%87%D0%B8%D1%81%D0%BB%D0%BE#%D0%9D%D0%BE%D0%BC%D0%B5%D1%80%D0%B8_%D0%86%D0%9D%D0%9F%D0%9F

  const deltas = [-1, 5, 7, 9, 4, 6, 10, 5, 7]
  const date = (inn + "").substr(0, 9).split("")
  const checkDigit = +(inn + "").substr(9, 9)
  const sum = date.map((n, i) => +n * deltas[i]).reduce((ac, n) => ac + n)
  return (sum % 11) % 10 === checkDigit
}

export const validateInn = inn => {
  if (/^(\d{10})$/.test(inn)) {
    return validateCheckDigit(inn)
  }
  return false
}

export const validateCode = code => /^([0-9]{4})$/.test(code.toString())
