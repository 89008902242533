import React, { useState,  }  from 'react'
import InputField from '../InputField';
import {Range} from 'react-range'
import delimiterFormat from '../../helpers/delimiter';
import {new_function_find_avarage_payment, moneyFormatToNumber, debounce} from '../../helpers/calc'

import './index.scss'

const RefinanceCalculator = () => {

    const [sum, setSum] = useState(0)
    const [monthPayment, setMonthPayment] = useState(0)
    const [count, setCount] = useState(36)
    const [checkField, setCheckField] = useState(false)

    const calc = debounce(value => {
        setCheckField(true)
        setMonthPayment(value)
      }, 100)

    const handleChangeSum = value => setSum(moneyFormatToNumber(value))
    const handleChangeMonthPayment = value => {
        calc(moneyFormatToNumber(value))
    }

    const average = checkField ? new_function_find_avarage_payment(+sum, +count) : 0
    const determ = monthPayment - average
    
    return (
        <div className="refinance-calculator">
            <div className="refinance-calculator__wrapper">
                <div className="refinance-calculator__col">
                    <InputField 
                        title={'Ваша поточна заборгованість, грн'}
                        style={{backgroundColor: 'transparent'}}
                        value={delimiterFormat(sum)}
                        change={(value) => handleChangeSum(value)}
                    />
                </div>
                <div className="refinance-calculator__col">
                    <div className="form-group form-group--range">
                    <span>Термін кредитування, міс</span>
                    <span>{count}</span>
                    <Range 
                        step={1} 
                        min={6}
                        max={60}
                        values={[count]}
                        onChange={(count) => setCount( count )} 
                        renderTrack={({ props, children }) => (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '7px',
                                width: '100%',
                                backgroundColor: '#184b89'
                            }}
                            >
                            {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '18px',
                                width: '18px',
                                outline: 'none',
                                backgroundColor: '#184b89'
                            }}
                            />
                        )}
                        />
                    </div>
                </div>
                <div className="refinance-calculator__col">
                    <InputField
                        title={'Поточний щомісячний платіж, грн'} 
                        style={{backgroundColor: 'transparent'}}
                        value={delimiterFormat(monthPayment)}
                        change={(value) => handleChangeMonthPayment(value)}
                    />
                </div>
                <div className="refinance-calculator__col">
                    <div className="form-group">
                        <span>Економія за місяць, грн</span>
                        <div className="refinance-calculator__result">{determ > 0 ? delimiterFormat(determ) : ""}</div>
                    </div>
                </div>
                <div className="refinance-calculator__col">
                    <div className="form-group">
                        <span>Щомісячний платіж у ТАСКОМБАНКУ, грн</span>
                        <div className="refinance-calculator__result">{
                            checkField && average > 0
                            ? delimiterFormat(average.toLocaleString("currency"))
                            : " "
                        }</div>
                    </div>
                </div>
                <div className="refinance-calculator__col">
                    <div className="form-group">
                        <span>Економія за весь період, грн</span>
                        <div className="refinance-calculator__result">{determ > 0 ? delimiterFormat(determ * count) : ""}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RefinanceCalculator;