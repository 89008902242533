import React from "react"

import "./index.scss"

const ButtonSubmit = ({ title }) => {
  return (
    <div className="form-btn-wrapper">
      <button className="btn btn--red regular">
        {title}{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Capa_1"
          enable-background="new 0 0 551.122 551.122"
          height="512"
          viewBox="0 0 551.122 551.122"
          width="512"
        >
          <path d="m275.561 551.122c-2.573 0-5.163-.572-7.535-1.749-108.955-53.048-199.139-110.3-199.139-273.816v-189.451c0-7.249 4.524-13.708 11.336-16.18l189.451-68.892c3.801-1.379 7.972-1.379 11.773 0l189.451 68.891c6.812 2.472 11.336 8.931 11.336 16.18v189.451c0 163.516-90.184 220.768-199.139 273.816-2.371 1.178-4.961 1.75-7.534 1.75zm-172.228-452.957v177.392c0 128.482 57.992 182.454 172.228 239.135 114.236-56.681 172.228-110.653 172.228-239.135v-177.392l-172.228-62.618s-172.228 62.618-172.228 62.618z" />
          <path d="m263.384 344.447-81.068-81.068 24.354-24.354 56.714 56.714 108.382-108.383 24.354 24.354z" />
        </svg>
      </button>
    </div>
  )
}

export default ButtonSubmit
