import { useReducer } from "react"
import Swal from "sweetalert2/dist/sweetalert2"
import axios from "axios"

import { smsErrors, onFail, onRule, onStatusError } from "../UI/PopUp"

import formActions from "../actions"

import {
  validateName,
  validatePhoneNumber,
  transformPhoneAfterMask,
  validateInn,
  validateCode,
} from "../helpers/validation"
import { getUrlParams } from "../helpers/params"

const {
  SEND_SMS,
  START_FETCH,
  FINISH_FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  CHANGE_NAME,
  CHANGE_INN,
  VALID_INN,
  CHECKED_INN,
  VALID_NAME,
  CHANGE_PHONE,
  VALID_PHONE,
  CHECKED_NAME,
  CHECKED_PHONE,
  TOGGLE_RULE,
} = formActions

const initialState = {
  fetch: false,
  sendSMS: false,
  success: false,
  fail: false,
  name: { valid: false, value: "", checked: false },
  phone: { valid: false, value: "", checked: false },
  inn: { valid: false, value: "", checked: false },
  rule: true,
}

const checkChannel = () => {
  const { utm_source } = getUrlParams()
  //console.log("utm_source", utm_source)

  return utm_source
}

const checkMedium = () => {
  const { utm_medium } = getUrlParams()
  //console.log("utm_medium", utm_medium)

  return utm_medium
}

const checkСampaign = () => {
  const { utm_campaign } = getUrlParams()
  //console.log("utm_campaign", utm_campaign)

  return utm_campaign
}

const checkContent = () => {
  const { utm_content } = getUrlParams()
  //console.log("utm_content", utm_content)

  return utm_content
}

const checkTerm = () => {
  const { utm_term } = getUrlParams()
  //console.log("utm_term", utm_term)

  return utm_term
}

// const checkSalesChannel = () => {
//   const {aff_sub, aff_id} = getUrlParams();

//   try {
//     const checkCookieAffSub = getCookie('CLICK_ID');
//     const checkCookieAffId = getCookie('AID');

//     if(checkCookieAffSub) {
//       if(checkCookieAffSub === aff_sub) {
//         setCookie('CLICK_ID', aff_sub, 30);
//         setCookie('AID', aff_id, 30);
//       }
//       else {
//         return {
//           click_id: checkCookieAffSub,
//           aid: checkCookieAffId
//         }
//       }
//     } else {
//       if(aff_sub && aff_id) {
//         setCookie('CLICK_ID', aff_sub, 30);
//         setCookie('AID', aff_id, 30);
//         return {
//           click_id: aff_sub,
//           aid: aff_id
//         }
//       }
//     }

//     return {
//       aff_sub: aff_sub,
//       aff_id: aff_id
//     }
//   }

//     catch (err) {
//       console.error(err);
//     }

//   return '';

// }

const reducer = (state, action) => {
  switch (action.type) {
    case SEND_SMS:
      return { ...state, sendSMS: action.payload }
    case START_FETCH:
      return { ...state, fetch: true }
    case FINISH_FETCH:
      return { ...state, ...initialState }
    case FETCH_SUCCESS:
      return { ...state, fetch: false, success: true }
    case FETCH_FAIL:
      return { ...state, fetch: false, fail: true }

    case CHANGE_NAME:
      return { ...state, name: { ...state.name, value: action.payload } }
    case VALID_NAME:
      return {
        ...state,
        name: { ...state.name, valid: action.payload },
      }

    case CHANGE_INN:
      return { ...state, inn: { ...state.inn, value: action.payload } }
    case VALID_INN:
      return { ...state, inn: { ...state.inn, valid: action.payload } }
    case CHECKED_INN:
      return { ...state, inn: { ...state.inn, checked: true } }

    case CHECKED_NAME:
      return { ...state, name: { ...state.name, checked: true } }
    case CHECKED_PHONE:
      return { ...state, phone: { ...state.phone, checked: true } }

    case CHANGE_PHONE:
      return { ...state, phone: { ...state.phone, value: action.payload } }
    case VALID_PHONE:
      return {
        ...state,
        phone: { ...state.phone, valid: action.payload },
      }
    case TOGGLE_RULE:
      return {
        ...state,
        rule: !state.rule,
      }

    default:
      return state
  }
}

const useForm = link => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const startSendSms = value => dispatch({ type: SEND_SMS, payload: value })

  //UI
  const startFetch = () => dispatch({ type: START_FETCH })
  const finishFetch = () => dispatch({ type: FINISH_FETCH })
  const fetchSuccess = () => dispatch({ type: FETCH_SUCCESS })
  const fetchFail = () => dispatch({ type: FETCH_FAIL })

  const toggleRule = () => dispatch({ type: TOGGLE_RULE })

  //Name
  const checkedName = () => dispatch({ type: CHECKED_NAME })

  const validName = isValidName =>
    dispatch({ type: VALID_NAME, payload: isValidName })
  const changeName = name => {
    dispatch({ type: CHANGE_NAME, payload: name })
    const isValidName = validateName(name)

    validName(isValidName)
  }

  //INN
  const checkedInn = () => dispatch({ type: CHECKED_INN })

  const validInn = isValidInn =>
    dispatch({ type: VALID_INN, payload: isValidInn })
  const changeInn = inn => {
    dispatch({ type: CHANGE_INN, payload: inn })

    const isValidInn = validateInn(inn)

    validInn(isValidInn)
  }

  //Phone
  const checkedPhone = () => dispatch({ type: CHECKED_PHONE })

  const validPhone = isValidPhone =>
    dispatch({ type: VALID_PHONE, payload: isValidPhone })
  const changePhone = phone => {
    dispatch({ type: CHANGE_PHONE, payload: phone })
    const normalPhone = transformPhoneAfterMask(phone)

    const isValidPhone = validatePhoneNumber(normalPhone)
    validPhone(isValidPhone)
  }

  const onClick = async () => {
    try {
      const { phone, inn, name, rule } = state

      ///Transform form after mask
      const normalPhone = transformPhoneAfterMask(phone.value)

      //Validate fileds
      const isValidPhone = validatePhoneNumber(normalPhone)
      const isValidName = validateName(name.value)
      const isValidInn = validateInn(inn.value)

      checkedPhone()
      checkedInn()
      checkedName()

      //Set fields
      validPhone(isValidPhone)
      validName(isValidName)
      validInn(isValidInn)

      if (isValidPhone && isValidName && isValidInn) {
        if (!rule) {
          onRule()
          return
        }

        send_status_to_db(
          normalPhone,
          name.value,
          inn.value,
          "Refinance landing",
          "FAILED"
        )

        startSendSms(true)
      }
    } catch (error) {
      console.log("err", error)
    }
  }

  const sendDataToServer = async () => {
    const { phone, inn, name, rule } = state

    const normalPhone = transformPhoneAfterMask(phone.value)

    const utmSource = checkChannel()
    const utmMedium = checkMedium()
    const utmСampaign = checkСampaign()
    const utmContent = checkContent()
    const utmTerm = checkTerm()

    startFetch()

    try {
      const res = await axios.post(
        "https://easycash.tascombank.ua/api/core-utm/refinance",
        {
          name: name.value,
          phone: normalPhone,
          inn: inn.value,
          source: utmSource ? `${utmSource}` : "landing",
          medium: utmMedium ? `${utmMedium}` : "landing",
          campaign: utmСampaign ? `${utmСampaign}` : "landing",
          utm_content: utmContent ? `${utmContent}` : "landing",
          utm_term: utmTerm ? `${utmTerm}` : "landing",
        }
      )

      //console.log(res)
      if (res.status === 201 || res.status == 200) {
        await send_status_to_db(
          normalPhone,
          name.value,
          inn.value,
          "Refinance landing",
          "SUCCESS"
        )
        window.location.href = "https://refinance.tascombank.ua/typ"
        finishFetch()
      } else {
        fetchFail()
        await send_status_to_db(
          normalPhone,
          name.value,
          inn.value,
          "Refinance landing",
          "FAILED"
        )
      }
    } catch (error) {
      console.log("error", error)
      fetchFail()
      await send_status_to_db(
        normalPhone,
        name.value,
        inn.value,
        "Refinance landing",
        "FAILED"
      )
    } finally {
      finishFetch()
      return
    }
  }

  const sendSMSReq = async () => {
    const smsLink = "https://tascombank.ua/api/sms/send"
    const normalPhone = transformPhoneAfterMask(state.phone.value)

    return await axios
      .post(smsLink, {
        phoneNumber: `${normalPhone}`,
        source: "refinance_landing",
      })
      .then(response => {
        if (response.data.status === "ok") {
          return response.data.timeout ? response.data.timeout : null
        }
      })
      .catch(error => {
        if (error.response && smsErrors.hasOwnProperty(error.response.status)) {
          if (error.response.status == 452) {
            send_status_to_db(
              normalPhone,
              state.name.value,
              state.inn.value,
              "Refinance landing",
              "REPEATED"
            )
          }
          onStatusError(state.phone.value, error.response.status, smsContext)
          //Swal.showValidationMessage(smsErrors[error.response.status])
        } else {
          onFail()
          send_status_to_db(
            normalPhone,
            state.name.value,
            state.inn.value,
            "Refinance landing",
            "FAILED"
          )
        }
      })
  }

  const reSendSmsReq = async () => {
    const smsLinkReSend = "https://tascombank.ua/api/sms/resend"
    const normalPhone = transformPhoneAfterMask(state.phone.value)

    return await axios
      .post(smsLinkReSend, {
        phoneNumber: `${normalPhone}`,
        source: "refinance_landing",
      })
      .then(() => {
        return true
      })
      .catch(error => {
        if (error.response && smsErrors.hasOwnProperty(error.response.status)) {
          if (error.response.status === 454) {
            send_status_to_db(
              normalPhone,
              state.name.value,
              state.inn.value,
              "Refinance landing",
              "REPEATED"
            )
            onStatusError(state.phone.value, 454, smsContext)
            //Swal.showValidationMessage(smsErrors[454](error.response.data))
          } else {
            if(error.response.status === 452) {
              send_status_to_db(
                normalPhone,
                state.name.value,
                state.inn.value,
                "Refinance landing",
                "REPEATED"
              )
            }
            onStatusError(state.phone.value, error.response.status, smsContext)
            //Swal.showValidationMessage(smsErrors[error.response.status])
          }
        } else {
          onFail()
          send_status_to_db(
            normalPhone,
            state.name.value,
            state.inn.value,
            "Refinance landing",
            "FAILED"
          )
        }
      })
  }

  const confirmSmsReq = async code => {
    const smsLinkConfirm = "https://tascombank.ua/api/sms/confirm"
    const normalPhone = transformPhoneAfterMask(state.phone.value)
    try {
      if (!validateCode(code)) {
        Swal.showValidationMessage(smsErrors[453])
      } else {
        return await axios
          .post(smsLinkConfirm, {
            code: code,
            phoneNumber: `${normalPhone}`,
            source: "refinance_landing",
          })
          .then(response => {
            if (response.data.status === "ok") {
              startSendSms(false)
              sendDataToServer()
            } else {
              onFail()
              send_status_to_db(
                normalPhone,
                state.name.value,
                state.inn.value,
                "Refinance landing",
                "FAILED"
              )
            }
          })
          .catch(error => {
            if (
              error.response &&
              smsErrors.hasOwnProperty(error.response.status)
            ) {
              Swal.showValidationMessage(smsErrors[error.response.status])
            } else {
              onFail()
              send_status_to_db(
                normalPhone,
                state.name.value,
                state.inn.value,
                "Refinance landing",
                "FAILED"
              )
            }
          })
          .finally(() => {
            channel_landing_otp(`+${normalPhone}`)
          })
      }
    } catch (err) {
      console.error(err)
      onFail()
    }
  }

  const channel_landing_otp = async (phone = null) => {
    try {
      if (!phone) {
        console.error("channel_landing_otp error: Phone is not exist")
        return
      }

      const data = {
        channel: "refinance",
        mobilePhone: phone,
      }

      await axios.post(
        "https://forms.tascombank.ua/api/v1/lending-channel",
        data
      )
    } catch (err) {
      console.error("Error channel_landing_otp", err)
    }
  }

  const log_form_submit = async () => {

    const { phone, name} = state

    try {
      if (!phone) {
        console.error("log_form_submit error: Phone is not exist")
        return
      }

      const data = {
        source: "refinance landing",
        phone: phone.value,
        name: name.value || "No name",
      }

      await axios.post(
        "https://tascombank.ua/api/form-log",
        data
      )
    } catch (err) {
      console.error("Error log_form_submit", err)
    }
  }

  const send_status_to_db = async (phone = "", name = "", inn = "", source = "Refinance landing", status = "FAILED") => {
    try {
      if (!phone) {
        console.error("send_status_to_db error: Phone is not exist")
        return
      }

      if (!source) {
        console.error("send_status_to_db error: Source is not exist")
        return
      }

      const utmMedium = checkMedium()
      const utmСampaign = checkСampaign()
      const utmSource = checkChannel()

      const data = {
        source: "Refinance landing",
        phone: phone,
        name: name,
        inn: inn.toString(),
        status: status,
        utm_campaign: utmСampaign ? utmСampaign : "Refinance landing",
        utm_medium: utmMedium ? utmMedium : "Refinance landing",
        utm_source: utmSource ? utmSource : "Refinance landing",
        date: Date.now(),
      }

      await axios.post("https://tascombank.ua/api/form-log", data)
    } catch (err) {
      console.error("Error send_status_to_db", err)
    }
  }

  const smsContext = {
    startSendSms,
    sendSMSReq,
    reSendSmsReq,
    confirmSmsReq,
  }

  return {
    state,
    smsContext,
    changeName,
    checkedName,
    toggleRule,
    changeInn,
    checkedInn,
    checkedPhone,
    changePhone,
    onClick,
  }
}

export default useForm
