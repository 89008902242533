//Core
import React, {useEffect} from "react"
import SEO from "../components/Seo";
import About from "../sections/About";
import Calculator from "../sections/Calculator";
import Footer from "../sections/Footer";
import Main from '../sections/Main'
import Order from "../sections/Order";

import './index.scss';

const IndexPage = () => {

    useEffect(() => {
        
    });

    return (
        <div className="content">
            <SEO 
                title="Рефінансування кредитів без переплат | ТАСКОМБАНК"
                description={`Почни рефінансування кредиту вже зараз разом з ТАСКОМБАНК та позбався від кредитних кайданів💸 Рефінансуємо кредити інших банків в ТАСКОМБАНК на прозорих умовах та без прихованих відстоків і платежів⚖️`}
            />
            <Main />
            <About />
            <Calculator />
            <Order />
            <Footer />
        </div>
    )
}

export default IndexPage
