import React from "react"

import Container from "../../components/Container"
import Form from "../../components/Form"

import "./index.scss"

const Order = () => {
  return (
    <div className="section order">
      <Container>
        <div className="order__header">
          <div className="order__header--large extrabold">Залишайте заявку</div>
          <div className="order__header--small regular">
            і сплачуйте менше вже завтра
          </div>
        </div>
        <Form />
      </Container>
    </div>
  )
}

export default Order
